const storageKey = "trackingData";

/*
 * manageQueryParams ensures that query parameters are stored in sessionStorage and appended to URL if missing.
 */
export function manageQueryParams(): TrackingData | null {
  const searchParams = new URLSearchParams(window.location.search);
  const params = fromURL(searchParams);
  let stored = loadTrackingData();
  if (!params && !stored) {
    // we must be missing a call to this function when entering the page
    console.error("tracking data missing");
    return null;
  }
  if (params) {
    if (
      !stored ||
      params.adobe_mc !== stored.adobe_mc ||
      params.app_container !== stored.app_container
    ) {
      stored = params;
      storeTrackingData(params);
    }
  }

  if (!searchParams.has("app_container")) {
    const url = new URL(window.location.href);
    if (stored.adobe_mc) {
      url.searchParams.set("adobe_mc", stored.adobe_mc);
    }
    url.searchParams.set("app_container", stored.app_container);
    window.history.pushState(null, "", url.toString());
  }
  return stored;
}

function setupTracking() {
  const w = window as any;
  if (!w.pageName) {
    return;
  }
  const data = manageQueryParams();
  const pagePrefix = data && data.app_container ? "penny-app" : "penny-de";
  const pageNameComplete = pagePrefix + `:account:${w.pageName}`;
  w.pageData = {
    page: {
      name: pageNameComplete,
      type: "account",
      channel: "account",
      hierarchy: [pagePrefix, "account"],
    },
  };
  w.trackSinglePageView = function (pageName) {
    w.pageData.page.name = pagePrefix + ":account:" + pageName;
    if (w._satellite) {
      w._satellite.track("pageView", w.pageData);
    }
  };
  const adobeScript = document.createElement("script");
  adobeScript.src =
    "https://assets.adobedtm.com/8665cc68f8ef/302850a9b963/launch-ee7b3712c1ad-staging.min.js";
  if (window.location.host === "account.penny.de")
    adobeScript.src =
      "https://assets.adobedtm.com/8665cc68f8ef/302850a9b963/launch-465a61895221.min.js";
  adobeScript.async = true;
  document.head.appendChild(adobeScript);
}

export interface TrackingData {
  adobe_mc: string | null;
  app_container: string;
}

function loadTrackingData(): TrackingData | null {
  const data = sessionStorage.getItem(storageKey);
  if (data) {
    return JSON.parse(data);
  }
  return null;
}

function storeTrackingData(data: TrackingData) {
  sessionStorage.setItem(storageKey, JSON.stringify(data));
}

function fromURL(params: URLSearchParams): TrackingData | null {
  if (params.has("app_container")) {
    return {
      adobe_mc: params.get("adobe_mc"),
      app_container: params.get("app_container"),
    };
  }
  if (params.has("redirect_uri")) {
    const uri = new URL(params.get("redirect_uri"));
    if (uri.searchParams.has("app_container")) {
      return {
        adobe_mc: uri.searchParams.get("adobe_mc"),
        app_container: uri.searchParams.get("app_container"),
      };
    }
  }
  return null;
}

setupTracking();
